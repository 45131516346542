declare var require: any;
require.config({
    paths: {
        "domready": "scripts/libraries/domready/ready.min",

        "knockout": "scripts/libraries/knockout/knockout-latest",
        "jquery": "scripts/libraries/jquery/jquery.min",

        "jquery-validate": "scripts/libraries/jquery-validation/jquery.validate.min",
        "jquery-validate-unobtrusive": "scripts/libraries/jquery-validation-unobtrusive/jquery.validate.unobtrusive.min",

        "bootstrap": "scripts/libraries/bootstrap/bootstrap.bundle.min",
        "datatables.net": "scripts/libraries/datatables.net/jquery.dataTables.min",
        "datatables.net-plugins": "scripts/libraries/datatables.net-plugins/dataTables.pageResize.min",
        "datatables.net-select": "scripts/libraries/datatables.net-select/dataTables.select.min",

        "datatables-bs4": "scripts/libraries/datatables.net-bs4/dataTables.bootstrap4.min",
        "i18next-ko": "scripts/libraries/i18next-ko/i18next-ko.bundle",
        "qrcode": "scripts/libraries/qrcode/qrcode.min",
        "chartjs": "scripts/libraries/chartjs/chart.min",
        "popper": "scripts/libraries/popperjs/popper.min",
        "fine-uploader": "scripts/libraries/fine-uploader/jquery.fine-uploader.min",
        "select2": "scripts/libraries/select2/select2.min",
        "jquery-sortable": "scripts/libraries/jquery-sortable/jquery-sortable-min",
        "ckeditor4": "scripts/libraries/ckeditor4/ckeditor",
        "moment": "scripts/libraries/moment/moment-with-locales.min",
        "bootstrap-treeview": "scripts/libraries/bootstrap-treeview/bootstrap-treeview",
        "sortablejs": "scripts/libraries/sortablejs/Sortable.min",
        //"signalr": "scripts/libraries/@aspnet/signalr.min",

        "html2canvas": "scripts/libraries/html2canvas/html2canvas.min",

        "autocompleter": "scripts/libraries/autocompleter/autocomplete.min",
        "bowser": "scripts/libraries/bowser/bundled",

        "showdown": "./scripts/libraries/showdown/showdown.min",
        "viewerinit": "scripts/ApplicationPDS/viewerinit",
        "editorinit": "scripts/ApplicationPDS/editorinit",
        "Viewer": "scripts/ApplicationPDS/viewer",
        "Editor": "scripts/ApplicationPDS/editor",
        "Application": "scripts/Application/application",

        "Survey": "scripts/Temp/viewer/survey.ko",
        "SurveyEditor": "scripts/Temp/editor/surveyeditor",
        //Survey: "https://surveyjs.azureedge.net/1.0.44/survey.ko",
        //SurveyEditor: "https://surveyjs.azureedge.net/1.0.44/surveyeditor",
        //"SurveyWidget": "scripts/ApplicationPDS/customwidget",
        
        "authenticator": "scripts/Pages/Account/authenticator",

        "pageContainer": "scripts/Pages/Home/pageContainer",

        "manage.index": "scripts/Pages/Manage/index",

        //"shared": "scripts/Pages/Shared",
        "home.index": "scripts/Pages/Home/index",
        "organisation.index": "scripts/Pages/Organisation/index",
        "organisation.create": "scripts/Pages/Organisation/create",
        "eligibilityquestionnaire.index": "scripts/Pages/EligibilityQuestionnaire/index",
        "application.index": "scripts/Pages/Application/index",
        "report.index": "scripts/Pages/Report/index",
        "user.index": "scripts/Pages/User/index",
        "message.index": "scripts/Pages/Message/index",
        "scheme.index": "scripts/Pages/Scheme/index",

        "award.index": "scripts/Pages/Award/index",
        "participant.index": "scripts/Pages/Participant/index",
        "claim.index": "scripts/Pages/Claim/index",


        "search.index": "scripts/Pages/Search/index",
        "supplier.index": "scripts/Pages/Supplier/index",
        "service.index": "scripts/Pages/Service/index",
        "scoring.index": "scripts/Pages/Scoring/index",
        "review.index": "scripts/Pages/Review/index",
        "admin.index": "scripts/Pages/Admin/index",

        
        //"index2": "scripts/Pages/home/index2",
        //"index3": "scripts/Pages/home/index3"
    },
    shim: {
        'bootstrap': {
            deps: ['jquery']
        },
        'jquery-validate': ['jquery'],
        'jquery-validate-unobtrusive': ['jquery', 'jquery-validate'],
        'ckeditor4': {
            exports: 'CKEDITOR'
        },
        'Viewer': {
            deps: ['datatables.net', 'moment']
        }
        //'Survey': {
        //    deps: ['SurveyWidget']
        //}
    },
    map: {
        "*": {
            "survey-knockout": "Survey"
        }
    }
});